import { graphql } from "gatsby";
import * as React from "react";
import PageHeader from "../components/ui/PageHeader";
import RolloverSection from "../components/ui/RolloverSection";
import { Img } from "../classes/Img";
import { HyperLink } from "../classes/HyperLink";
import { Page, Pages } from "../classes/Page";
import { RichTextReact } from "wmk-rich-text";
import ContactARegionalEngineer from "../routes/AskAnExpert/ContactARegionalEngineer/RegionalEngineers";
import MemberCompaniesContent from "../routes/About/MemberCompanies/MemberCompanies";
import StaffContent from "../routes/About/Staff/Staff";
import AlliancesContent from "../routes/About/Alliances/Alliances";
import AdvocacyForm from "../routes/Advocacy/AdvocacyForm";
import EventList from "../components/ui/Events/Events";
import NewsAndCommentaryPage from "../components/ui/News/NewsAndCommentaryPage/NewsAndCommentaryPage";
import SubscribeForm from "../components/ui/News/SubscribeForm";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import LogoAndTaglinePageContent from "../routes/LogoAndTagline";
import OrderInstallationGuidePage from "../routes/OrderInstallationGuide";
import AskAnEngineerForm from "../routes/AskAnExpert/AskAnEngineer/AskAnEngineerForm";
import { PageFields } from "../fragments/NodePageFields";
import { RolloverLink, RolloverMenuFields } from "../fragments/NodeMenuFields";
import { WmkSeo } from "wmk-seo";
import AdBank from "../components/ui/AdBank";
import ConferenceAssetContent from "../routes/Conference";
import AlgoliaApi from "../routes/AlgoliaApi";
import { options } from "../components/ui/RichText/richTextOptions";
import SearchResults from "../routes/SearchResultsPage";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { SearchContext } from "../context/SearchContext";
import { Container } from "react-bootstrap";
import EventsMainPage from "../components/ui/Events/EventsMainPage";

const ResiliencePageWrapper = styled.div`
  .gatsby-image-wrapper-constrained:first-child {
    margin: 1rem 25% 0;
  }
  @media only screen and (max-width: 766px) {
    .gatsby-image-wrapper-constrained:first-child {
      margin: 1rem 10% 0;
    }
  }
`;

const ValuePageWrapper = styled.div`
  .gatsby-image-wrapper {
    width: 90% !important;
    left: -5%;
  }
  .img-col {
    margin: 3rem 0;
  }
  :not(.col) > h3 {
    padding-left: calc(8.33333333%);
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (min-width: 576px) {
    :not(.col) > h3 {
      max-width: 540px;
      padding-left: 8px;
    }
  }
  @media only screen and (min-width: 768px) {
    :not(.col) > h3 {
      max-width: 720px;
      padding-left: calc(78px - 38px);
      // padding-right: 20px;
    }
  }
  @media only screen and (min-width: 992px) {
    :not(.col) > h3 {
      max-width: 960px;
      padding-left: calc(96.66px - 38px);
      // padding-left: 96.66px;
    }
  }
  @media only screen and (min-width: 1200px) {
    :not(.col) > h3 {
      max-width: 1140px;
      padding-left: calc(111.66px - 38px);
      // padding-left: 111.66px;
    }
  }
  @media only screen and (min-width: 1400px) {
    :not(.col) > h3 {
      max-width: 1320px;
      padding-left: calc(126px - 38px);
      // padding-left: 126px;
    }
  }
`;

// Register paths here for special-case pages
const pathHashTable = {
  "/ask-an-expert/contact-a-regional-engineer": ContactARegionalEngineer,
  "/ask-an-expert/ask-an-engineer": () => {
    return (
      <>
        <div style={{ paddingTop: "3rem" }}></div>
        <AskAnEngineerForm isContactPage />
      </>
    );
  },
  "/about-dipra/member-companies": MemberCompaniesContent,
  "/about-dipra/dipra-staff": StaffContent,
  "/about-dipra/alliances": AlliancesContent,
  "/advocacy/support-the-issues": AdvocacyForm,
  "/news/news-and-commentary": NewsAndCommentaryPage,
  "/news/upcoming-events": EventsMainPage,
  "/ask-an-expert/where-dipra-will-be": EventsMainPage,
  "/news/dipra-e-newsletters/subscribe": SubscribeForm,
  "/news/dipra-e-newsletters": SubscribeForm,
  "/logo-and-tagline": LogoAndTaglinePageContent,
  "/technical-resources/design-steps/installation/order-an-installation-guide":
    OrderInstallationGuidePage,
  "/conference": ConferenceAssetContent,
  "/algoapi": AlgoliaApi,
  "/results": SearchResults
};

const getRolloverLinks = (pgSlug: string, links: RolloverLink[]) =>
  links.map((pg) => {
    const {
      featuredImage,
      secondaryImage,
      title,
      headline,
      sub,
      parentPath,
      slug
    } = pg;
    return {
      featuredImage: new Img(featuredImage),
      secondaryImage: new Img(secondaryImage),
      title: title,
      headline: headline !== "" ? headline : undefined,
      text: sub?.text ? sub.text : "",
      button: new HyperLink({
        slug,
        parentPath:
          pgSlug === "technical-resources"
            ? `/${pgSlug}${parentPath}`
            : parentPath,
        title
      })
    };
  });

interface PageNodeProps {
  pageContext: {
    parentPath: string;
    slug: string;
  };
  data: PageNodeQuery;
}

const PageNode = ({ pageContext, data }: PageNodeProps) => {
  const { slug, parentPath, headline, subhead, sub } = data.page;
  const isParentPage = parentPath === "/";
  const route = `${!isParentPage ? parentPath : ""}/${slug}`;
  const RouteComponent: React.FunctionComponent =
    route in pathHashTable ? pathHashTable[route] : () => <></>;
  let rolloverData = null;
  const page = new Page(data.page);
  const content = page.content;

  if (isParentPage && slug !== "advocacy") {
    if (slug !== "technical-resources") {
      const allRollovers = data.rollovers.edges.map((e) => e.node);
      rolloverData = allRollovers.reduce((match, menu) => {
        const dashExp = new RegExp(`-`, `g`);
        const exp = new RegExp(`${slug.replace(dashExp, " ")} Menu`, "i");
        const { title, links } = menu;
        if (Array.isArray(title.match(exp)) && !title.includes("Newsroom")) {
          const newLinks = getRolloverLinks(slug, links);
          match = match.concat(newLinks);
        }
        return match;
      }, []);
    } else {
      rolloverData = getRolloverLinks(slug, data.techRollovers.links);
    }
  }
  const ContentJSX = content ? (
    slug && slug.includes("ad-bank") ? (
      <AdBank content={content} />
    ) : (
      <RichTextReact content={content} options={options} />
    )
  ) : null;
  const RolloverJSX =
    isParentPage && rolloverData ? (
      <RolloverSection rollovers={rolloverData} />
    ) : null;
  const RouteCompJSX = RouteComponent ? <RouteComponent /> : null;
  return (
    <>
      {slug === "results" && (
        <SearchContext.Consumer>
          {(consumerData) => {
            return (
              <Helmet>
                <script>
                  {`window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event' : 'search',
                    'searchTerm' : '${consumerData.searchInput}'
                  });
          `}
                </script>
              </Helmet>
            );
          }}
        </SearchContext.Consumer>
      )}
      <WmkSeo.Meta
        title={page.title}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={page.parentPath === "/" ? slug : `/${slug}`}
        path={page.parentPath ? page.parentPath : ""}
        description={page.meta}
        ogImageUrl={page.image ? page.image.url("ogImage") : ""}
        twitterImageUrl={page.image ? page.image?.url("twitterImage") : ""}
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <DipraBreadcrumbs route={`${parentPath}/${slug}`} />
      <PageHeader
        headline={headline}
        subhead={subhead}
        subtext={sub?.text ? sub.text : ""}
      />
      {(ContentJSX || RolloverJSX || RouteCompJSX) && slug === "resilience" ? (
        <ResiliencePageWrapper>
          {ContentJSX}
          {RolloverJSX}
          {RouteCompJSX}
        </ResiliencePageWrapper>
      ) : (ContentJSX || RolloverJSX || RouteCompJSX) && slug === "value" ? (
        <ValuePageWrapper>
          {ContentJSX}
          {RolloverJSX}
          {RouteCompJSX}
        </ValuePageWrapper>
      ) : (ContentJSX || RolloverJSX || RouteCompJSX) &&
        (slug ===
          "dipra-regional-engineer-region-north-mid-atlantic-northeast" ||
          slug === "careers") ? (
        <Container style={{ paddingBottom: "2rem" }}>
          {ContentJSX}
          {RolloverJSX}
          {RouteCompJSX}
        </Container>
      ) : (ContentJSX || RolloverJSX || RouteCompJSX) &&
        slug === "contact-us" ? (
        <>
          <AskAnEngineerForm />
          {ContentJSX}
          {RolloverJSX}
          {RouteCompJSX}
          <div style={{ paddingBottom: "2rem" }}></div>
        </>
      ) : (ContentJSX || RolloverJSX || RouteCompJSX) &&
        slug === "media-newsroom" ? (
        <>
          {ContentJSX}
          {RouteCompJSX}
        </>
      ) : ContentJSX || RolloverJSX || RouteCompJSX ? (
        <>
          {ContentJSX}
          {RolloverJSX}
          {RouteCompJSX}
        </>
      ) : (
        <>
          {console.log("no dynamic template for data:", pageContext)}
          Dynamic Page Template
        </>
      )}
    </>
  );
};

export default PageNode;

export interface PageNodeQuery {
  page: PageFields;
  rollovers: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  techRollovers: RolloverMenuFields;
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
}

export const query = graphql`
  query pageQuery($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...NodePageFields
      ...PageBlockIconCopy
      ...PageBlockImageCopy
      ...PageBlockIconCard
      ...PageBlockTestimonial
      ...PageBlockColumns
      ...PageYouTubeVideo
      ...PageBlockSocials
      ...PageAsset
    }
    rollovers: allContentfulMenu(filter: { title: { regex: "/ Menu/i" } }) {
      edges {
        node {
          ...NodePageRolloverMenuFields
        }
      }
    }
    techRollovers: contentfulMenu(
      title: { eq: "Technical Resources Rollovers" }
    ) {
      ...NodePageRolloverMenuFields
    }
    site {
      siteMetadata {
        baseUrl
        description
        title
        twitter
      }
    }
  }
`;
