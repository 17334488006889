import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import MemberCompanyCard from "./MemberCompanyCard";
import { MemberCompanies } from "../../../classes/MemberCompany";

const Wrap = styled.div`
  * {
  }
  padding: 4rem 0;
`;

const MemberCompaniesContent = ({onlyLogos}: {onlyLogos?: boolean}) => {
  const data = useStaticQuery(query);
  const memComs = new MemberCompanies(
    data?.members?.edges ? data.members.edges : []
  );
  return (
    <Wrap style={onlyLogos ? {paddingTop: 0} : {}}>
      <Container>
        <Row>
          {memComs.list.map((member, i: number) => {
            return (
              <Col xl={3} key={member.name + i}>
                <MemberCompanyCard member={member} onlyLogos={onlyLogos} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Wrap>
  );
};

export default MemberCompaniesContent;

const query = graphql`
  query {
    members: allContentfulMemberCompany(sort: { fields: title }) {
      edges {
        node {
          ...NodeMemberCompanyFields
        }
      }
    }
  }
`;
